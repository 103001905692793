*, *::after, *::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Urbanist', sans-serif;
}

html, body{
  scroll-behavior: smooth;
}

a, a:visited{
       text-decoration: none;
       color: inherit;             
}

@media screen  and (max-width: 380px){
  .inactive{
  position: relative;
  animation: fadeOutUp 1s !important;
  animation-fill-mode: forwards;
  opacity: 0;
  }
}


@keyframes rotateCustom1 {
  100% {transform:rotate(45deg); }
}

@keyframes rotateCustom1 {
  100% {transform:rotate(45deg); }
}

@keyframes rotateCustom2 {
  100% {transform:rotate(-45deg); }
}
@keyframes rotateBackCustom1 {
  100% {
      transform:rotate(-45deg);
      opacity: 1;
   }
}

@keyframes rotateBackCustom2 {
  100% {transform:rotate(45deg); }
}

@keyframes rollback {
  100% {transform:rotate(180deg); }
}

.hidden{
  display: none !important;
  visibility: none;
}